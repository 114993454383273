<template>
  <div class="public-body">
    <div class="public-body__block public-body__block--decor">
      <img src="@/assets/img/mkp-logo.svg" alt="logo" />
    </div>
    <div class="public-body__block">
      <div class="public-body__title  title">Условия возврата, доставки и процесса описания передачи данныx</div>
      <div class="public-body__subt">Описание возврата товара или услуги</div>
      <p>Если карта утеряна службой доставки:</p>
      <p>Необходимо на электронную почту info@mkp-ruy.ru направить письмо:</p>
      <p>Тема «Возврат средств за онлайн заказ № __» Содержание письма «Я, __ (ФИО), хочу вернуть средства за заказ № __ , в связи с __ (указание причины)»</p>
      <p>После получения запроса мы уточняем у службы доставки статус заказа и, при подтверждении факта потери с их стороны, на вашу почту в течение суток будет направлено письмо с шаблоном заявления на возврат денежных средств и разъяснением дальнейших действий.</p>
      <p>После получения нами заполненного и подписанного (от руки) заявления, оно передается на рассмотрение в бухгалтерию. Если у них, в свою очередь, не возникнет вопросов по предоставленной информации, возврат денежных средств будет осуществлен в течение 10 календарных дней со дня получения заявления на электронную почту info@mkp-ruy.ru</p>
      <p>Если карта не может быть оформлена, т.к. не были соблюдены условия оформления: Необходимо на электронную почту info@mkp-ruy.ru направить письмо:</p>
      <p>Тема «Возврат средств за онлайн заказ № __»</p>
      <p>Содержание письма «Я, __ (ФИО), хочу вернуть средства за заказ № __ , в связи с __ (указание причины)»</p>
      <p>После получения запроса, на вашу почту в течение суток будет направлено ответное письмо с шаблоном заявления на возврат денежных средств и разъяснением дальнейших действий. После получения нами заполненного и подписанного (от руки) заявления, оно передается на рассмотрение в бухгалтерию. Если у них, в свою очередь, не возникнет вопросов по предоставленной информации, возврат денежных средств будет осуществлен в течение 10 календарных дней со дня получения заявления на электронную почту info@mkp-ruy.ru</p>
      <p>Если на карте некорректно указаны данные</p>
      <p>Необходимо на электронную почту info@mkp-ruy.ru направить письмо:</p>
      <p>Тема «Ошибка/брак в оформленной карте. Заказ № __»</p>
      <p>Содержание письма «На полученной мною карте некорректно заполнена информация/имеется брак (уточняете какая именно проблема)»</p>
      <p>Важно! Необходимо прикрепить фото, на котором четко видна ошибка или брак. Если ошибка была допущена сотрудником организации, то клиенту будет оформлена новая карта (в течение 10 календарных дней).</p>
      <p>При самовывозе:</p>
      <p>Если ошибка была выявлена при заборе карты в офисе, то карта будет исправлена на месте в течение 15 мин.</p>
      <p>Если ошибка была выявлена вне офиса, то новая карта будет выслана вам службой доставки по удобному адресу. Условия отправления будут обговариваться индивидуально с клиентом. ИЛИ: Необходимо на электронную почту info@mkp-ruy.ru направить письмо:</p>
      <p>Тема «Возврат средств за онлайн заказ № __»</p>
      <p>Содержание письма «Я, __ (ФИО), хочу вернуть средства за заказ № __ , в связи с __ (указание причины)»</p>
      <p>ВАЖНО! Вы не можете вернуть денежные средства за карту, если данные на ней указаны с ошибкой, допущенной вами при заполнении анкеты.</p>
      <div class="public-body__subt">Условия доставки товара</div>
      <p>Карта изготавливается в городе Москве и доставляется держателю Почтой России по адресу, указанному держателем при приобретении карты.</p>
      <div class="public-body__subt">Описание процесса передачи данных</div>
      <p>«Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.
        Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.»</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dos'
};
</script>
